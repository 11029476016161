<template>
    <v-container fluid>
        <v-row>
            <v-col cols="8">
                <trev-cool-table :courier="$courier.Scopes" ref="tbl"
                    :headers="[{ text: 'Scope', value: 'scope' }, { text: 'Actions', value: 'actions' }]">
                    <template #title>
                        <v-icon class="mr-2">
                            mdi-folder-key-outline
                        </v-icon>
                        Scopes
                    </template>
                    <template #item.actions="{ item }">
                        <v-btn color="danger" @click="deleteScope(item)" small>
                            <v-icon class="mr-2">
                                mdi-delete
                            </v-icon>
                            Delete
                        </v-btn>
                    </template>
                </trev-cool-table>
            </v-col>
            <v-col>
                <v-card>
                    <v-toolbar>
                        <v-toolbar-title>
                            <v-icon class="mr-2">
                                mdi-plus
                            </v-icon>
                            Add Scope
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-text-field label="New Scope" v-model="newScope" @keydown.enter="addScope" :error-messages="errorMessage" :error="!!errorMessage">
                        </v-text-field>
                        <v-btn color="primary" small @click="addScope">
                            <v-icon class="mr-2">
                                mdi-save
                            </v-icon>
                            Add Scope
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import trevCoolTable from '../../../../components/tables/trev-cool-table.vue'
export default {
    components: { trevCoolTable },
    methods: {
        deleteScope(scope) {
            this.$courier.Scopes.removeById(scope.id).then(() => {
                this.$refs.tbl.getData();
            })
        },
        addScope() {
            if (this.newScope) {
                //search for the new to see if exists
                this.$courier.Scopes.getData(1, 200, this.newScope).then(data => {
                    if (data && data.payload && data.payload.length > 0 && data.payload.some(x => x.scope === this.newScope)) {
                        this.errorMessage = 'Scope with name: ' + this.newScope + ' already exists';
                    }
                    else {
                        //add it

                        this.$courier.Scopes.add({ scope: this.newScope }).then(() => {
                            this.$refs.tbl.getData();
                            this.newScope = '';
                            this.errorMessage = '';
                        });
                    }
                })
            }
            else{
                this.errorMessage = 'Invalid Scope';
            }
        }
    },
    data() {
        return {
            errorMessage: '',
            newScope: ''
        }
    },
}
</script>

<style></style>